import Index from './components/Index'

function App() {
    return (
    <div className="App">
        <Index />
    </div>
    );
}

export default App;
