import React from 'react';
import logo from '../home/DSP_logo_small.png';

function Home() {    

    return (
    <div>
        <main role="main">
            <div className="container">
                <header>
                    <img src={logo} alt="" />
                    {/* <h2><strong>PhoneDekho.com</strong></h2> */}
                    <h3>Website Coming Soon!</h3>
                </header>
            </div>
        </main>
        <footer className="py-3 fixed-bottom">
            <div className="container">
            {/* <a href="mailto:info@deoriasewapranyas.org"><i className="fa fa-envelope"></i>&nbsp;info@deoriasewapranyas.org</a> */}
            </div>
        </footer>
    </div>
    )
}

export default Home
